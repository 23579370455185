// 企业微信手机端的路由
let routers = [
    //移动端
    {path: '/mobile/login', name: '登录-手机', component: () => import("../views/mobile/login/MobileLogin")},
    {path: '/mobile/main', name: '登录-主页', component: () => import("../views/mobile/main/Main"),
        children: [
            {path: '/mobile/home', name: '主页-手机', component: () => import("../views/mobile/main/QywxHome")},
            {path: '/mobile/info', name: '个人信息-手机', component: () => import("../components/mobile/LoginUser")},
            {path: '/mobile/customer/client', name: '客户列表-手机', component: () => import("../views/mobile/customer/client/ClientList")},
            {path: '/mobile/customer/records', name: '跟进记录-手机', component: () => import("../views/mobile/customer/record/RecordList")},
            {path: '/mobile/exhibit/project', name: '陈列活动列表-手机', component: () => import("../views/mobile/exhibit/project/ProjectList")},
            {path: '/mobile/exhibit/projectCheck', name: '陈列活动审批列表-手机', component: () => import("../views/mobile/exhibit/project/ProjectCheckList")},
            {path: '/mobile/exhibit/client', name: '陈列提报列表-手机', component: () => import("../views/mobile/exhibit/client/ClientList")},
            {path: '/mobile/exhibit/clientCheck', name: '陈列提报审批列表-手机', component: () => import("../views/mobile/exhibit/client/ClientCheckList")},
            {path: '/mobile/exhibit/gather', name: '陈列采集列表-手机', component: () => import("../views/mobile/exhibit/gather/GatherList")},
            {path: '/mobile/exhibit/gatherCheck', name: '陈列采集审批列表-手机', component: () => import("../views/mobile/exhibit/gather/GatherCheckList")},
            {path: '/mobile/exhibit/view', name: '陈列汇总列表-手机', component: () => import("../views/mobile/exhibit/view/ProjectViewList")},
            {path: '/mobile/process/form', name: '流程申报列表-手机', component: () => import("../views/mobile/process/form/ProcessList")},
            {path: '/mobile/process/customer', name: '流程申报自定义流程-手机', component: () => import("../views/mobile/process/customerProcess/CustomerProcessList")},
            {path: '/mobile/bi/employee', name: 'bi看板，按职员统计', component: () => import("../views/mobile/bi/employee/BiEmployee")},
            {path: '/mobile/bi/client', name: 'bi看板，按客户统计', component: () => import("../views/mobile/bi/employee/BiClient")},
            {path: '/mobile/bi/product', name: 'bi看板，按商品统计', component: () => import("../views/mobile/bi/employee/BiProduct")},
            {path: '/mobile/bi/order', name: 'bi看板，订单明细', component: () => import("../views/mobile/bi/employee/BiOrder")},
            {path: '/mobile/hwd/siteCost', name: '进场费用申请单', component: () => import("../views/mobile/dzfw/hwd/mobileSiteCost/MobileSiteCostList")},
            {path: '/mobile/hwd/saleApply', name: '促销服务协议', component: () => import("../views/mobile/dzfw/hwd/mobileSaleApply/MobileSaleApplyList")},
            {path: '/mobile/hwd/salePromotion', name: '促销申请单', component: () => import("../views/mobile/dzfw/hwd/mobileSalePromotion/MobileSalePromotionList")},
            // 佰豪定制审批功能
            {path: '/mobile/baihao/buyOrderBack', name: '采购退货单', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoBuyOrderBack.vue")},
            {path: '/mobile/baihao/payApply', name: '付款申请单', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoPayApply.vue")},
            {path: '/mobile/baihao/buyOrder', name: '采购订单', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoBuyOrder.vue")},
            {path: '/mobile/baihao/saleOrder', name: '销售订单', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoSaleOrder.vue")},
            {path: '/mobile/baihao/buyApply', name: '采购申请单', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoBuyApply.vue")},
            {path: '/mobile/baihao/saleOrderBack', name: '销售退货单', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoSaleOrderBack.vue")},
            {path: '/mobile/baihao/priceList', name: '价目表', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoPrice.vue")},
            {path: '/mobile/baihao/contract', name: '合同', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoContract.vue")},
            {path: '/mobile/baihao/priceChange', name: '调价单', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoPriceChange.vue")},
            {path: '/mobile/baihao/travelBill', name: '差旅报销单', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoTravelBill.vue")},
            {path: '/mobile/baihao/costBill', name: '费用报销单', component: () => import("../views/mobile/dzfw/baihao/checkOrder/BaiHaoCostBill.vue")},
            {path: '/mobile/baihao/reportCost', name: '现金收付表', component: () => import("../views/mobile/dzfw/baihao/report/BaiHaoReportCost.vue")},
            {path: '/mobile/baihao/reportReceive', name: '应收报表', component: () => import("../views/mobile/dzfw/baihao/report/BaiHaoReportReceive.vue")},
            {path: '/mobile/baihao/reportPay', name: '应付报表', component: () => import("../views/mobile/dzfw/baihao/report/BaiHaoReportPay.vue")},
        ]
    },
    {path: '/mobile/customer/client/detail', name: '客户详情-手机', component: () => import("../views/mobile/customer/client/ClientDetail")},
    {path: '/mobile/customer/records/detail', name: '跟进详情-手机', component: () => import("../views/mobile/customer/record/RecordDetail")},
    {path: '/mobile/customer/records/customer', name: '跟进详情-按客户统计', component: () => import("../views/mobile/customer/record/RecordCustomer")},
    {path: '/mobile/exhibit/project/detail', name: '陈列项目-手机', component: () => import("../views/mobile/exhibit/project/ProjectDetail")},
    {path: '/mobile/exhibit/projectCheck/detail', name: '陈列项目审批-手机', component: () => import("../views/mobile/exhibit/project/ProjectCheckDetail")},
    {path: '/mobile/exhibit/client/detail', name: '陈列提报-手机', component: () => import("../views/mobile/exhibit/client/ClientDetail")},
    {path: '/mobile/exhibit/clientCheck/detail', name: '陈列提报审批-手机', component: () => import("../views/mobile/exhibit/client/ClientCheckDetail")},
    {path: '/mobile/exhibit/gather/detail', name: '陈列采集-手机', component: () => import("../views/mobile/exhibit/gather/GatherDetail")},
    {path: '/mobile/exhibit/gatherCheck/detail', name: '陈列采集审批-手机', component: () => import("../views/mobile/exhibit/gather/GatherCheckDetail")},
    {path: '/mobile/exhibit/view/detail', name: '陈列汇总-手机', component: () => import("../views/mobile/exhibit/view/ProjectViewDetail")},
    {path: '/mobile/process/form/detail', name: '流程申报详情-手机', component: () => import("../views/mobile/process/form/ProcessDetail")},
    {path: '/mobile/statement/iou', name: '欠条管理-手机', component: () => import("../views/mobile/statement/iou/IouBillList")},
    {path: '/mobile/statement/customer', name: '欠条汇总-手机', component: () => import("../views/mobile/statement/iou/IouCustomerList.vue")},
    {path: '/mobile/statement/customer/list', name: '欠条管理-手机', component: () => import("../views/mobile/statement/iou/IouBillList2")},
    {path: '/mobile/statement/iou/detail', name: '欠条详情-手机', component: () => import("../views/mobile/statement/iou/IouBillDetail")},
    {path: '/mobile/hwd/siteCost/detail', name: '进场费用申请单详情', component: () => import("../views/mobile/dzfw/hwd/mobileSiteCost/MobileSiteCostDetail")},
    {path: '/mobile/hwd/saleApply/detail', name: '促销服务协议详情', component: () => import("../views/mobile/dzfw/hwd/mobileSaleApply/MobileSaleApplyDetail")},
    {path: '/mobile/hwd/salePromotion/detail', name: '促销申请单详情', component: () => import("../views/mobile/dzfw/hwd/mobileSalePromotion/MobileSalePromotionDetail")},
    // 佰豪定制功能，审批详情
    {path: '/mobile/baihao/saleOrderBack/detail', name: '促销申请单详情', component: () => import("../views/mobile/dzfw/baihao/checkOrder/component/BaiHaoCheckOrderDetail.vue")},
    // 绿田定制
    {path: '/mobile/lvtian/order/detail', name: '配送列表', component: () => import("../views/mobile/dzfw/lvtian/detail/orderDetail.vue")},
    {path: '/mobile/lvtian/order/finalDetail', name: '单据详情', component: () => import("../views/mobile/dzfw/lvtian/detail/FinalDetail.vue")},
    {path: '/mobile/lvtian/deliveryOrder', name: '配送订单', component: () => import("../views/mobile/dzfw/lvtian/DeliveryOrder.vue")},
];
export default routers;