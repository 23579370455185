let baseUrl = "";  // 默认路径
let fileUrl = "";  // 文件资源访问路径
let systemCode = "";
let merchantUuid = "";
let appid = "";
let suiteId = "";
const mapKey = "5TGBZ-XVMCU-TUPVV-BN3P6-FWH4V-DKBRV"

switch (process.env.NODE_ENV) {
    case 'development':
        appid = "ww4a983ad1815241ec";
        suiteId = "ww50bb496d97adba01";
        // baseUrl = `http://blzbkj.gnway.org:7009/server`;
        baseUrl = `https://server.yqgj.ltd`;
        // baseUrl = `http://192.168.5.168:9002`;
        baseUrl = `http://192.168.5.168:9002`;

        // fileUrl = `http://blzbkj.gnway.org:7009/file/`;
        fileUrl = `https://file.yqgj.ltd/`;
        // fileUrl = `https://file.yqgj.ltd/`;
        fileUrl = `http://192.168.5.168:7091/`;

        break
    case 'production':
        appid = "ww7a95d26192ad3638";
        suiteId = "wwa32edfbc623c7e4a";
        baseUrl = `https://server.yqgj.ltd`;
        fileUrl = `https://file.yqgj.ltd/`;
        break
}

export default {
    baseUrl, fileUrl, systemCode, merchantUuid, mapKey, appid, suiteId,
    systems: {
        provider: {name: "供应商管理平台", code: "Prov", type: 1},
        platform: {name: "订货管理平台", code: "Plat", type: 2},
        distributor: {name: "分销商管理平台", code: "Distr", type: 3},
        wholesaler: {name: "批发商管理平台", code: "Whs", type: 4},
        store: {name: "门店管理平台", code: "Sto", type: 5},
    },
    // 客户数据来源
    clientOrigin: [
        {dictCode: "1", dictName: "手动添加"},
        {dictCode: "2", dictName: "转介绍"},
    ],
    // 陈列项目的状态
    projectStatus: [
        {dictCode: 0, dictName: "草稿"},
        {dictCode: 1, dictName: "已发布"},
        {dictCode: 2, dictName: "进行中"},
        {dictCode: 3, dictName: "结束"},
    ],
    // 项目审批的状态，通用
    projectCheckStatus: [
        {dictCode: 1, dictName: "通过"},
        {dictCode: 0, dictName: "待审批"},
        {dictCode: -1, dictName: "拒绝"},
    ],
    // 对账管家的支付状态
    payStatus: [
        {dictCode: -1, dictName: "已过期"},
        {dictCode: 0, dictName: "未发起"},
        {dictCode: 1, dictName: "待支付"},
        {dictCode: 2, dictName: "支付完成"},
        {dictCode: 3, dictName: "支付失败"},
    ],
    // 对账管家的单据类型
    billType:[
        {dictCode: "1", dictName: "销售出库单"},
        // {dictCode: "2", dictName: "销售退货单"},
    ],
    // 欠条的单据类型
    iouBillType: [
        {dictCode: "1", dictName: "销售出库单"},
        {dictCode: "2", dictName: "销售退货单"},
        {dictCode: "4", dictName: "应收增加单"},
        {dictCode: "5", dictName: "应收减少单"},
        {dictCode: "6", dictName: "往来核销单"},
        {dictCode: "7", dictName: "费用单"},
    ],
    // 欠条状态
    iouStockStatus: [
        {dictCode: 1, dictName: "在库"},
        {dictCode: 10, dictName: "出库"},
        {dictCode: 99, dictName: "完结"},
    ],
    // 欠条执行阶段
    iouProcessStatus: [
        {dictCode: 1, dictName: "在库单据"},
        {dictCode: 2, dictName: "领单申请"},
        {dictCode: 10, dictName: "已出库未执行"},
        {dictCode: 11, dictName: "待收款确认"},
        {dictCode: 12, dictName: "已对账未收款"},
        {dictCode: 13, dictName: "退单申请"},
        {dictCode: 99, dictName: "已收款"},
    ]
}